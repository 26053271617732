import React from "react"
import { images } from "src/ui/Image/images"

export interface ImageProps extends React.HTMLProps<HTMLSpanElement> {
  type: keyof typeof images
  ariaLabel?: string
  svgProps?: React.SVGProps<SVGSVGElement>
}

function Image(props: ImageProps) {
  const { type, ariaLabel, svgProps, ...spanProps } = props
  const SVG = images[type].src
  return (
    <span role="img" aria-label={ariaLabel || images[type].ariaLabel} {...spanProps}>
      <SVG {...svgProps} />
    </span>
  )
}

export { Image }
