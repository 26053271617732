import { Button as AntDButton, ButtonProps as AntDButtonProps } from "antd"
import React, { ReactElement, ForwardedRef } from "react"
import styled, { css } from "styled-components"

import { Icon, IconTypes } from "src/ui/Icon"
import { isIconType } from "src/ui/Icon/Icon.tsx"

export interface ButtonProps extends AntDButtonProps {
  icon?: ReactElement | IconTypes
  iconSize?: string
}

const StyledButton = styled(AntDButton)<{ $iconOnly: boolean }>`
  box-shadow: none;

  ${({ $iconOnly }) =>
    $iconOnly
      ? ""
      : css`
          display: flex;
          align-items: center;
          max-width: 100%;

          > span {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        `}
`

function ButtonForwardRef(
  {
    type = "default",
    loading = false,
    disabled = false,
    danger = false,
    icon,
    iconSize,
    title: _title,
    "aria-label": _ariaLabel,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const iconOnly = Boolean(icon && !props.children)
  const title = _title ?? _ariaLabel
  const ariaLabel = _ariaLabel ?? _title

  if (iconOnly && !title) {
    console.warn("Untitled icon button", icon)
  }

  let iconElement = undefined
  if (icon && !loading) {
    if (React.isValidElement(icon)) {
      iconElement = icon
    } else if (isIconType(icon)) {
      iconElement = <Icon name={icon} size={iconSize ?? "14px"} disabled={disabled} />
    }
  }

  const defaultIconStyles = iconOnly
    ? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }
    : {
        textOverflow: "initial",
        overflow: "initial",
      }

  const styles = { icon: { ...defaultIconStyles, ...props.styles?.icon }, ...props.styles }

  return (
    <StyledButton
      $iconOnly={iconOnly}
      ref={ref}
      type={type}
      loading={loading}
      disabled={disabled || Boolean(loading)}
      danger={danger}
      icon={iconElement}
      styles={styles}
      title={title}
      aria-label={ariaLabel}
      {...props}
    />
  )
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(ButtonForwardRef)

export { Button }
