import { NavigateOptions, useLocation, useNavigate } from "react-router-dom"
import { extractOrgSlug } from "src/authentication/Auth0ProviderWithHistory"

type OrganizationSlugUtils = {
  orgSlug: string
  navigateInOrg: (to: string, options?: NavigateOptions) => void
  orgPrefixedPath: (path: string) => string
}

type OptionalOrganizationSlugUtils = { exists: false } | ({ exists: true } & OrganizationSlugUtils)

export function useOptionalOrganizationSlug(): OptionalOrganizationSlugUtils {
  const navigate = useNavigate()
  const location = useLocation()

  // NOTE: ideally the slug would be accessible via the router, but that would require a larger refactor. This is good enough for now.
  const orgSlug = extractOrgSlug(location.pathname)

  if (orgSlug === undefined) {
    return { exists: false }
  }

  const orgPrefixedPath = (path: string) => {
    const relativePath = path.charAt(0) === "/" ? path.slice(1) : path
    return `/organizations/${orgSlug}/${relativePath}`
  }

  // NOTE: This has a more minimal interface than `navigate` (for now).
  const navigateInOrg = (to: string, options?: NavigateOptions) => {
    navigate(orgPrefixedPath(to), options)
  }
  return {
    exists: true,
    orgSlug,
    navigateInOrg,
    orgPrefixedPath,
  }
}

export function useOrganizationSlug(): OrganizationSlugUtils {
  const optionalOrgSlugUtils = useOptionalOrganizationSlug()

  // If the user called useOrganizationSlug, they expect the slug to exist.
  // If they are unsure, they should call useOptionalOrganizationSlug directly
  if (!optionalOrgSlugUtils.exists) {
    throw Error("useOrganizationSlug can only be called from routes with an organization slug")
  }

  return optionalOrgSlugUtils
}
