import styled, { css } from "styled-components"

export const Background = styled.div`
  min-height: 100vh;
  background-image: url("https://app.greatexpectations.io/static/media/data-hexagons_lg2.b79bae852445ee2fc75812206d1e0c78.svg"),
    linear-gradient(180deg, #f0f0f0 0%, #eeeef3 55.5%, #ebecff 100%);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
`

export const ScrollableMainContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: safe center;
  justify-content: center;
  ${({ theme }) => css`
    padding: ${theme.spacing.horizontal.xs};
  `};
`
