/*
  Creates a flattened copy of a nested object
  Ex:
    const nested = {
      one: {
        two: {
          dos: "dos",
          three: "three",
        },
      },
      anotherOne: "anotherOne",
    }

  will return:
    const flattened = {
      dos: "dos",
      three: "three",
      anotherOne: "anotherOne",
    }

 */

export const flattenDeepObject = (obj: Record<string, unknown>) => {
  const flattened: Record<string, unknown> = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenDeepObject(value as Record<string, never>))
    } else {
      flattened[key] = value
    }
  })

  return flattened
}
