import { Typography } from "antd"
import { LinkProps } from "antd/lib/typography/Link"
import { LinkProps as RRLinkProps, useNavigate } from "react-router-dom"
import { useOptionalOrganizationSlug } from "src/organizations/useOrganizationSlug"

type MergedLinkProps = Omit<LinkProps & RRLinkProps, "href" | "ref">

export interface AppLinkProps extends MergedLinkProps {
  // to is the destination of the link
  to: string
  // newTab opens the destination in a new browser tab
  newTab?: boolean
}

function AppLink(props: AppLinkProps) {
  const { to, newTab, replace, ...other } = props
  const orgSlugUtils = useOptionalOrganizationSlug()
  const navigateOutsideOrg = useNavigate()
  const isExternal = /^(tel:|mailto:|https?:\/\/)/.test(to)
  // TODO: Figure out why target = "_blank" doesn't work for internal links
  // ticket - https://greatexpectations.atlassian.net/browse/FE-21
  const href = !isExternal && orgSlugUtils.exists ? orgSlugUtils.orgPrefixedPath(to) : to
  const navigate = orgSlugUtils.exists ? orgSlugUtils.navigateInOrg : navigateOutsideOrg
  const onClick: LinkProps["onClick"] = isExternal
    ? undefined
    : (event) => {
        event.preventDefault()
        navigate(to, { replace })
      }

  if (newTab || isExternal) {
    other.target = "_blank"
    other.rel = "noopener noreferrer"
  }

  return <Typography.Link href={href} onClick={onClick} {...other} />
}

export { AppLink }
