import { REACT_APP_AUTH0_AUDIENCE, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } from "src/common/env"

export default function getAuth0Config(): {
  domain: string
  clientId: string
  audience: string
} {
  const domain = REACT_APP_AUTH0_DOMAIN
  if (!domain) {
    throw new Error(`Error: No Auth0 domain environment variable found`)
  }
  const clientId = REACT_APP_AUTH0_CLIENT_ID
  if (!clientId) {
    throw new Error(`Error: No Auth0 client ID environment variable found`)
  }
  const audience = REACT_APP_AUTH0_AUDIENCE
  if (!audience) {
    throw new Error(`Error: No Auth0 audience environment variable found`)
  }

  return {
    domain,
    clientId,
    audience,
  }
}
