import React from "react"
import { Image } from "src/ui/Image"
import styled from "styled-components"
import { Heading1, LG } from "src/ui/typography/Text/Text"
import { CheckCircleFilled } from "@ant-design/icons"
import { Alert } from "antd"

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Footer = styled.footer`
  background-color: #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px 0;
  width: 100%;
  color: white;

  /* Use a high z-index for future-proofing */
  z-index: 10;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
`

const Terms = styled.span`
  text-align: center;
  padding: 0 32px;
  display: inline-block;
  margin: 0 4px;
`

const TermsLink = styled.a`
  color: white;
  margin-left: 5px;
`

const LoginModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 328px; /* 328px + 36px padding on both sides is 400px (desired width of the modal) */
  padding: 60px 36px;
  background-color: #fff;
  box-shadow: 0 12px 40px rgb(0 0 0 / 12%);
  border-radius: 16px;
  border-top: 1px solid #e9e9e9;
`

type SignupLoginModalProps = {
  loginHeaderTextOne?: string
  loginHeaderTextTwo?: string
  loginText?: string
  buttonText?: string
  headerSection?: React.ReactNode | string
  secondaryHeaderSectionText?: string
  formsSection?: React.ReactNode
  signupLoginModalChildren?: React.ReactNode
  hideFooter?: boolean
}

const LoginHeader = styled(Heading1)`
  margin-top: 36px;
`

const LoginHeaderSecondary = styled(LG)`
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
`

const StyledResultContainer = styled.div`
  padding-top: 20px;
`

const loginModalStyle = { height: "50px", width: "200px" }

const loginSuccess = new URL(window.location.href).searchParams.get("emailVerified") === "true"
const emailUnverified = new URL(window.location.href).searchParams.get("emailVerified") === "false"

export const SignupLoginModal: React.FC<SignupLoginModalProps> = ({
  headerSection,
  formsSection,
  signupLoginModalChildren,
  secondaryHeaderSectionText,
  hideFooter,
}) => {
  return (
    <>
      <MainContainer>
        <LoginModal>
          <Image
            aria-label="Great Expectations Logo"
            type="geLogoWithDarkText"
            svgProps={loginModalStyle}
            style={loginModalStyle}
          />
          {!loginSuccess && <LoginHeader>{headerSection}</LoginHeader>}
          {loginSuccess && (
            <StyledResultContainer>
              <CheckCircleFilled style={{ fontSize: "24px", color: "#109910" }} />
              <Heading1>Success!</Heading1>
              <LoginHeaderSecondary>Enter your email and password to log in</LoginHeaderSecondary>
            </StyledResultContainer>
          )}
          {emailUnverified && (
            <Alert
              message="Login error"
              type="error"
              description="Your email address has not been verified. Check your email for a verification link."
              showIcon
            />
          )}

          {!loginSuccess && <LoginHeaderSecondary>{secondaryHeaderSectionText}</LoginHeaderSecondary>}
          {formsSection}
          {!loginSuccess && signupLoginModalChildren}
        </LoginModal>
      </MainContainer>
      {!hideFooter && (
        <Footer>
          <Terms>
            By logging in to the application you accept
            <TermsLink href="https://greatexpectations.io/legal-center" target="_blank">
              the Terms of Service, Privacy Policy and Community Terms of Use
            </TermsLink>
          </Terms>
        </Footer>
      )}
    </>
  )
}
